var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"product-list"}},[_c('add-update',{attrs:{"product-type":_vm.productType,"product-item":_vm.productItem},on:{"refetch-data":_vm.fetchProducts,"save-product-event":_vm.saveProductEvent},model:{value:(_vm.isAddUpdateProductSidebarActive),callback:function ($$v) {_vm.isAddUpdateProductSidebarActive=$$v},expression:"isAddUpdateProductSidebarActive"}}),_c('v-snackbars',{attrs:{"objects":_vm.snackbars,"width":"500","top":"","right":"","transition":"slide-y-transition"},on:{"update:objects":function($event){_vm.snackbars=$event}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var close = ref.close;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}])}),_c('v-card',{class:[{ 'rounded-t-lg': !_vm.$vuetify.breakpoint.mdAndDown }, { 'rounded-l-xl': _vm.userData.role === 'SUPER' }],attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-toolbar',{attrs:{"color":_vm.isDark ? '#363351' : '#fafafa',"flat":"","height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiShape)+" ")]),_vm._v(" Ürün Kategorileri ")],1),_c('v-spacer'),_c('v-btn',{class:{
              'bg-gradient-nightDark': !_vm.isDark,
              'bg-gradient-primary': _vm.isDark, // isDark ise farklı bir stil uygula
              'text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text': true
            },attrs:{"depressed":"","x-large":""},on:{"click":function($event){return _vm.addProduct()}}},[_c('span',[_vm._v("Kategori Ekle")])])],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.productListTable,"items-per-page":_vm.itemsPerPage,"fixed-header":"","height":_vm.dataTableHeight,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,

          }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',{staticStyle:{"zoom":"0.8"}},[_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"disabled":"","filled":"","hide-details":""}})],1),_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"persistent-placeholder":"","placeholder":"Ara","filled":"","hide-details":""},model:{value:(_vm.kategoriSearch),callback:function ($$v) {_vm.kategoriSearch=$$v},expression:"kategoriSearch"}})],1),_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"filled":"","disabled":"","hide-details":""}})],1)])]},proxy:true},{key:"item.ust",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticStyle:{"width":"100%","justify-content":"center"},attrs:{"color":item.ust === '1' ? 'success' : 'warning',"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.ust === '1' ? 'Mamül' : 'Sarf')+" ")])]}},{key:"item.baslik",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-body-2 white--text"},[_vm._v(" "+_vm._s(item.baslik)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","color":"nightDark","small":""},on:{"click":function($event){return _vm.editProduct(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")]),_vm._v(" Düzenle ")],1),_c('v-btn',{staticClass:"text-capitalize",attrs:{"plain":"","small":""},on:{"click":function($event){return _vm.deleteProduct(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]),_vm._v(" Sil ")],1)]}}],null,true)})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }